import React from "react";
import { Route, Redirect } from "react-router-dom";
import VivialConnectAdminAPI from "api/vivial-connect-admin";

const ProtectecRoute = (props) => {
  const { component: Component, render, ...rest } = props;
  const { token } = VivialConnectAdminAPI.helpers;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token.get()) return <Redirect to="/login" />;
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectecRoute;
