import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import { lightTheme, darkTheme, alternativeTheme } from "../../../../themes";
import useStyles from "./styles";

export const Card = (props) => {
  const classes = useStyles();
  const { children, color = "light" } = props;

  const handleColor = (color) => {
    if (color === "dark") return darkTheme;
    if (color === "gray") return alternativeTheme;
    return lightTheme;
  };

  return (
    <ThemeProvider theme={handleColor(color)}>
      <Paper className={`${classes.paper} ${classes[color]}`}>{children}</Paper>
    </ThemeProvider>
  );
};

export const CardHeader = (props) => {
  const classes = useStyles();

  const {
    title,
    icon: IconComponent,
    iconClass,
    typography = { variant: "h5" },
  } = props;
  return (
    <div className={classes.cardHeader}>
      {IconComponent ? (
        <Avatar className={classes.avatar}>
          <IconComponent className={iconClass ? iconClass : classes.icon} />
        </Avatar>
      ) : null}
      <Typography {...typography} color="inherit">
        {title}
      </Typography>
    </div>
  );
};

export const CardContent = (props) => {
  const classes = useStyles();

  const { children, block = false, ...rest } = props;
  return (
    <Grid
      container
      spacing={3}
      className={block ? `${classes.paper} ${classes.block}` : classes.paper}
      {...rest}
    >
      {children}
    </Grid>
  );
};

export default {
  Card,
  CardHeader,
  CardContent,
};
