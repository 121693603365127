import React from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";

import MainAppBar from "./AppBar";
import SideBar from "./SideBar";

import { alternativeTheme, darkTheme } from "themes";
import { useStyles } from "./styles";

// Need two components here to properly support theming. Parent componenet
// applies the ThemeProvider, and the child component's useStyles() call
// receives the correct theme.
function DashMainComponent(props) {
  const classes = useStyles();
  const { children, handleDrawerToggle, noWrap } = props;
  const location = useLocation();

  /*
TODO:
For the Dashboard page, we need to modify the subtitle with the user's first
name. Getting the first name requires an extra round-trip to the API/DB so I
prefer to do it just once.

The current approach of refreshing the account status with every request,
makes this difficult. Adding the extra request to that process will increase
bandwidth and processing for each status request.

After we implement some sort of context persistence we can add user details to
status.

I'd like to find a way to make the user call independantly from pages/Dashboard
but I don't know to get that information back to this componenet so we can set
the subtitle here.

Luis Alejandro - 31/12/2020: I think we can leverage the use of react-helmet for this purpose.

:-/

*/

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div className={classes.children}>
        <MainAppBar handleDrawerToggle={handleDrawerToggle} {...props} />
        <ThemeProvider theme={alternativeTheme}>
          {noWrap ? (
            <>{children}</>
          ) : (
            <Paper className={classes.paper} key={location.pathname}>
              {/* key=location.pathname
              forces React to rerender paper component so scroll position is always on top */}

              <div className={classes.maxWidth}>{children}</div>
            </Paper>
          )}
        </ThemeProvider>
      </div>
    </main>
  );
}

export default function Dashboard(props) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = (isOpen) => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <ThemeProvider theme={darkTheme}>
        <SideBar
          handleDrawerToggle={handleDrawerToggle}
          drawerOpen={drawerOpen}
        />
        <DashMainComponent
          handleDrawerToggle={handleDrawerToggle}
          drawerOpen={drawerOpen}
          {...props}
        />
      </ThemeProvider>
    </div>
  );
}
