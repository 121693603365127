// campaigns/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  campaignImport: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/campaigns/import.json`,
  campaigns: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/campaigns.json`,
  campaign: (accountId, campaignId) =>
    `${helper.BASE_URL}/accounts/${accountId}/campaigns/${campaignId}.json`,
  brands: (accountId) => `${helper.BASE_URL}/accounts/${accountId}/brands.json`,
  verticals: () => `${helper.BASE_URL}/campaigns/verticals.json`,
  useCases: () => `${helper.BASE_URL}/campaigns/usecases.json`,
  brandUseCases: (accountId, brandId) =>
    `${helper.BASE_URL}/accounts/${accountId}/brands/${brandId}/usecases.json`,
  qualifyCampaign: (accountId, brandId, useCase) =>
    `${helper.BASE_URL}/accounts/${accountId}/brands/${brandId}/usecases/${useCase}.json`,
  prices: () => `${helper.BASE_URL}/accounts/campaigns/prices.json`,
};
