import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles.js";

import DashboardLayout from "components/Layouts/Dashboard";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AccountBody = ({ children, accountId }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const basePath = `/account/${accountId}`;

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <div className={classes.accountSection}>
      <AppBar position="static">
        <Tabs
          value={location.pathname}
          onChange={handleChange}
          aria-label="nav tabs example"
          variant="fullWidth"
        >
          <Tab value={`${basePath}`} label="Details" />
          <Tab value={`${basePath}/billing`} label="Billing" />
          <Tab value={`${basePath}/logs`} label="Logs" />
          <Tab value={`${basePath}/settings`} label="Settings" />
          <Tab value={`${basePath}/contacts`} label="Contacts" />
        </Tabs>
      </AppBar>
      <TabPanel value={location.pathname} index={location.pathname}>
        {children}
      </TabPanel>
    </div>
  );
};

export default function TabContent({ children, companyName }) {
  let { id: accountId } = useParams();
  const classes = useStyles();

  return (
    <DashboardLayout title={companyName} subtitle={accountId} noWrap={true}>
      <Paper className={classes.accountSelected}>
        <hr />
        <AccountBody accountId={accountId}>{children}</AccountBody>
      </Paper>
    </DashboardLayout>
  );
}
