import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  accountSection: {
    height: "100%",
    overflowY: "auto",
  },
  accountSelected: {
    width: "100%"
  }
}));
