import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FormGroup,
  Checkbox,
  Grid,
  FormControlLabel,
  Switch,
  Button,
  TextField,
  FormControl,
} from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
  CardHeader as DashboardCardHeader,
} from "components/Layouts/Dashboard/Card";

import TabContent from "components/TabPanel";
import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";

import { stateOptionsName } from "./constants";
import { defaultAccount } from "../constants";
import ReferralsTable from "./ReferralsTable";
import useStyles from "./styles";

import {
  VivialConnectAdminAPI,
  VivialConnectAPI,
} from "api/vivial-connect-admin";
const { accounts } = VivialConnectAdminAPI.services;
const { registration } = VivialConnectAPI.services;

const checkboxRender = ({ checked, onChange, name, label }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} name={name} />}
      label={label}
    />
  );
};

const renderActiveAccount = (active, onAccountStatusChange, accountId) => {
  return (
    <>
      <h6>Account Status:</h6>

      <ConfirmModal
        title={active ? "Disable Account" : "Enable Account"}
        warningMessage={
          <span>
            Are you sure you want to{" "}
            <strong>{active ? "DISABLE" : "ENABLE"}</strong> account{" "}
            <strong>{accountId}</strong>?
          </span>
        }
        clickableComponent={
          <FormControlLabel
            control={<Switch checked={active} value={active} />}
            label={"Active"}
            style={{
              flex: 1,
            }}
          />
        }
        onSubmit={() => {
          onAccountStatusChange(!active);
        }}
      />
    </>
  );
};

export function Settings() {
  let { id: accountId } = useParams();
  const classes = useStyles();
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);
  const [active, setActive] = useState(true);
  const [stopFiltering, setStopFiltering] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [CSPID, setCSPID] = useState("");
  const [stateOption, setStateOption] = React.useState({
    disable_renewal: false,
    disable_edit: false,
    disable_cancel: false,
    disable_international: false,
    disable_upgrade: false,
  });
  const { contacts } = selectedAccount;
  let contact = {};
  if (contacts.length > 0) {
    const main_contact = contacts
      .filter((contact) => contact.contact_type === "main")
      .pop();
    if (main_contact) contact = main_contact;
  }

  const resendAccountVerificationEmail = () => {
    if (contact.email) {
      const payload = { email: contact.email };
      registration.postResendEmail(payload).then(() => {
        toast.success(`Email verification sent to ${contact.email}.`);
      });
    }
  };

  const handlePhoneVerified = (event) => {
    const payload = { account: { phone_verified: event.target.checked } };
    accounts.setAccountPhoneVerified(accountId, payload).then(() => {
      toast.success(`Phone verified updated for account ${accountId}.`);
    });
    setPhoneVerified(event.target.checked);
  };

  const handleStopFiltering = (value) => {
    const payload = { disabled: value };
    accounts.setAccountStopFiltering(accountId, payload).then(() => {
      toast.success(`Stop filtering updated for account ${accountId}.`);
    });
    setStopFiltering(value);
  };

  const handleReseller = () => {
    if (isReseller) return; //disable handler if reseller == true
    accounts
      .addAccountReseller(accountId)
      .then((data) => {
        setIsReseller(true);
        toast.success(
          `Reseller ID generated for account ${accountID}: ${data.resellerId}.`
        );
      })
      .catch((error) => toast.error(error));
  };

  const handleStateOptionChange = (event) => {
    const { name: optionName, checked: optionValue } = event.target;
    const option = { [optionName]: optionValue };
    accounts.setAccountStateOptions(accountId, option).then(() => {
      toast.success(
        `${stateOptionsName[optionName]} updated for account ${accountId}.`
      );
    });
    setStateOption({
      ...stateOption,
      [optionName]: optionValue,
    });
  };

  const handleCompanyNameChange = () => {
    const { accounts: accountServices } = VivialConnectAPI.services;
    const payload = {
      account: {
        company_name: companyName,
      },
    };
    accountServices.putAccount(payload, accountId).then(() => {
      toast.success(`Account ${accountId} name updated.`);
    });
  };

  const handleCSPIDChange = () => {
    const { accounts: accountServices } = VivialConnectAPI.services;
    const payload = {
      account: {
        csp_id: CSPID,
      },
    };
    accountServices.putAccount(payload, accountId).then(() => {
      toast.success(`Account ${accountId} CSP ID updated.`);
    });
  };

  const updateOptions = (stateOptions) => {
    let newOptions = {};
    Object.keys(stateOptionsName).map((key) => {
      newOptions[key] = stateOptions[key] ? stateOptions[key] : false;
      return newOptions;
    });
    setStateOption(newOptions);
  };

  const handleAccountStatusChange = async (checked) => {
    const { accounts } = VivialConnectAdminAPI.services;

    const payload = {
      account: {
        active: checked,
      },
    };

    accounts
      .setAccountActive(accountId, payload)
      .then((account) => {
        setActive(account.active);
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    async function fetchSettings() {
      const stopFiltering = await accounts.getAccountStopFiltering(accountId);
      setStopFiltering(stopFiltering);

      const accountStateOptions = await accounts.getAccountStateOptions(
        accountId
      );

      const { phone_verified } = await accounts.getAccountPhoneVerified(
        accountId
      );
      setPhoneVerified(phone_verified);

      if (accountStateOptions) {
        updateOptions(accountStateOptions);
      }
    }

    async function fetchAccount() {
      const { accounts } = VivialConnectAdminAPI.services;
      accounts
        .getAccount(accountId)
        .then((account) => {
          setSelectedAccount(account);
          setCompanyName(account.company_name);
          setCSPID(account.csp_id);
          setActive(account.active);
          setIsReseller(
            account.tcr_reseller_id !== undefined && account.tcr_reseller_id
          );
        })
        .catch((error) => {
          toast.error("An error ocurred");
        });
    }

    fetchSettings();
    fetchAccount();
  }, [accountId]);

  const {
    disable_cancel,
    disable_edit,
    disable_international,
    disable_renewal,
    disable_upgrade,
  } = stateOption;

  return (
    <TabContent companyName={selectedAccount.company_name}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <DashboardCard>
            <DashboardCardHeader title="Plan Options" />
            <DashboardCardContent block={true}>
              <FormGroup>
                {checkboxRender({
                  checked: disable_renewal,
                  onChange: handleStateOptionChange,
                  name: "disable_renewal",
                  label: stateOptionsName["disable_renewal"],
                })}
                {checkboxRender({
                  checked: disable_edit,
                  onChange: handleStateOptionChange,
                  name: "disable_edit",
                  label: stateOptionsName["disable_edit"],
                })}
                {checkboxRender({
                  checked: disable_cancel,
                  onChange: handleStateOptionChange,
                  name: "disable_cancel",
                  label: stateOptionsName["disable_cancel"],
                })}

                {checkboxRender({
                  checked: disable_upgrade,
                  onChange: handleStateOptionChange,
                  name: "disable_upgrade",
                  label: stateOptionsName["disable_upgrade"],
                })}
              </FormGroup>
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
        <Grid item xs={4}>
          <DashboardCard>
            <DashboardCardHeader title="Account Options" />
            <DashboardCardContent block={true}>
              <FormControl fullWidth className={classes.textField}>
                <TextField
                  variant="outlined"
                  label="Company Name"
                  name="companyName"
                  id="companyName"
                  value={companyName}
                  onChange={(event) => setCompanyName(event.target.value)}
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleCompanyNameChange}
                      >
                        Save
                      </Button>
                    ),
                  }}
                />
              </FormControl>
              <FormControl fullWidth className={classes.textField}>
                <TextField
                  variant="outlined"
                  label="CSP ID"
                  name="CSPID"
                  id="CSPID"
                  value={CSPID}
                  onChange={(event) => setCSPID(event.target.value)}
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleCSPIDChange}
                      >
                        Save
                      </Button>
                    ),
                  }}
                />
              </FormControl>
              {renderActiveAccount(
                active,
                handleAccountStatusChange,
                accountId
              )}
              <FormGroup>
                <ConfirmModal
                  title={`${
                    stopFiltering ? "Enable" : "Disable"
                  } STOP Filtering`}
                  warningMessage={
                    <span>
                      Are you sure you want to{" "}
                      <strong>{stopFiltering ? "ENABLE" : "DISABLE"}</strong>{" "}
                      STOP Filtering for account <strong>{accountId}</strong>?
                    </span>
                  }
                  clickableComponent={
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stopFiltering}
                          name="stop_filtering"
                        />
                      }
                      label="Disable STOP Filtering"
                    />
                  }
                  onSubmit={() => {
                    handleStopFiltering(!stopFiltering);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ConfirmModal
                  title={`${
                    disable_international ? "Enable" : "Disable"
                  } international Messaging`}
                  warningMessage={
                    <span>
                      Are you sure you want to{" "}
                      <strong>
                        {disable_international ? "ENABLE" : "DISABLE"}
                      </strong>{" "}
                      international messaging for account{" "}
                      <strong>{accountId}</strong>?
                    </span>
                  }
                  clickableComponent={
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={disable_international}
                          name="disable_international"
                        />
                      }
                      label={stateOptionsName["disable_international"]}
                    />
                  }
                  onSubmit={() => {
                    let event = { target: {} };
                    event.target.checked = !disable_international;
                    event.target.name = "disable_international";
                    handleStateOptionChange(event);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ConfirmModal
                  title="Enable Reseller Account"
                  disabled={isReseller}
                  warningMessage={
                    <span>
                      Are you sure you want to designate this account as a
                      Reseller? You can not undo this action.
                    </span>
                  }
                  clickableComponent={
                    <FormControlLabel
                      control={
                        <Checkbox checked={isReseller} name="is_reseller" />
                      }
                      label="Enable Reseller Account"
                      disabled={isReseller}
                    />
                  }
                  onSubmit={() => {
                    handleReseller();
                  }}
                />
              </FormGroup>
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
        <Grid item xs={4}>
          <DashboardCard>
            <DashboardCardHeader title="Account Verification" />
            <DashboardCardContent block={true}>
              <FormGroup>
                <ConfirmModal
                  title={`Email Verification`}
                  warningMessage={
                    <>
                      <span>
                        Are you sure you wish to resend email verification to{" "}
                        {contact.email}?
                      </span>
                    </>
                  }
                  clickableComponent={
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<EmailIcon />}
                      fullWidth
                    >
                      Resend Verification Email
                    </Button>
                  }
                  onSubmit={resendAccountVerificationEmail}
                />
              </FormGroup>
              {checkboxRender({
                checked: phoneVerified,
                onChange: handlePhoneVerified,
                name: "phone_verified",
                label: "Phone verified",
              })}
            </DashboardCardContent>
          </DashboardCard>
        </Grid>

        <Grid item xs={12}>
          <DashboardCard>
            <DashboardCardHeader title="Referral Codes" />
            <DashboardCardContent block={true}>
              <ReferralsTable accountId={accountId} />
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
      </Grid>
    </TabContent>
  );
}

export default Settings;
