import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from "@material-ui/core";

import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import LockIcon from "@material-ui/icons/Lock";

import { Delete } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useStyles } from "./styles";

import VivialConnectAdminAPI from "api/vivial-connect-admin";
const { domainblacklist } = VivialConnectAdminAPI.services;

export const arrayByAlphabet = (object) => {
  object.sort((a, b) =>
    a.domain > b.domain ? 1 : b.domain > a.domain ? -1 : 0
  );
  return object;
};

const CustomRow = (props) => {
  const { item, onRemoveDomain } = props;

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell>{new Date(item.date_created).toISOString()}</TableCell>
      <TableCell>{item.domain}</TableCell>
      <TableCell>
        <IconButton onClick={() => onRemoveDomain(item.id, item.domain)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const RenderTable = (props) => {
  const { items, onRemoveDomain } = props;
  const classes = useStyles();
  if (Object.keys(items).length === 0) {
    return <div className="not-found">No Domains Found</div>;
  }

  return (
    <div className={classes.tableWrapper}>
      <Table aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>Added On</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => {
            return (
              <CustomRow
                key={item.id}
                item={item}
                onRemoveDomain={onRemoveDomain}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const AddDomainWidget = (props) => {
  const classes = useStyles();
  const { title, domain, onAddDomain, buttonValue, onDomainNameChange } = props;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onAddDomain();
      }}
      autoComplete="off"
    >
      <TextField
        id="domains-input"
        label={title}
        margin="normal"
        variant="outlined"
        value={domain}
        onChange={onDomainNameChange}
        InputProps={{
          className: classes.endAdorment,
          endAdornment: (
            <InputAdornment position="end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
              >
                {buttonValue}
              </Button>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </form>
  );
};

export default function BlacklistDomains() {
  const [blacklist, setBlacklist] = useState([]);
  const [count, setCount] = useState(0);
  const [domain, setDomain] = useState("");
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const limit = 150; // 150 is the API's maximum limit

  const handleDomainRemove = async (id, domainName) => {
    domainblacklist
      .removeDomain(id)
      .then(() => {
        toast.success(`Removed domain: ${domainName}`);
        fetchBlacklist();
      })
      .catch((error) => toast.error(error));
  };

  const handleDomainAdd = async () => {
    domainblacklist
      .addDomain(domain)
      .then(() => {
        toast.success(`Added domain: ${domain}`);
        fetchBlacklist();
        setDomain("");
      })
      .catch((error) => toast.error(error));
  };

  const handleDomainNameChange = (event) => {
    const domain = event.target.value;
    setDomain(domain);
  };

  const fetchBlacklist = useCallback(() => {
    const params = {
      page: page + 1, //Material expects 0-based pages. API expects 1-based.
      limit: limit,
    };

    domainblacklist
      .getDomainBlacklist(params)
      .then((allBlacklist) => {
        setBlacklist(arrayByAlphabet(allBlacklist));
      })
      .catch((error) => toast.error(error));

    domainblacklist
      .getDomainBlacklistCount(params)
      .then((count) => {
        setCount(count);
      })
      .catch((error) => toast.error(error));
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchBlacklist();
  }, [fetchBlacklist, page]);

  return (
    <DashboardCard>
      <DashboardHeader
        icon={LockIcon}
        iconClass={classes.text}
        title={"Blacklist"}
      />
      <DashboardCardContent alignContent="flex-start">
        <Grid container direction="column">
          <Grid item md={12}>
            <AddDomainWidget
              title="Domain name"
              domain={domain}
              buttonValue="Add To Blacklist"
              onDomainNameChange={handleDomainNameChange}
              onAddDomain={handleDomainAdd}
            />
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Grid item md={12}>
            <RenderTable
              items={blacklist}
              page={page}
              onRemoveDomain={handleDomainRemove}
            />
            <TablePagination
              component="div"
              count={count}
              rowsPerPageOptions={[limit]}
              rowsPerPage={limit}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={handleChangePage}
            />
          </Grid>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
}
