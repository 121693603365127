import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(3),
    maxHeight: 500,
  },
  tablePagination: {
    display: "flex",
    width: "100%",
    marginTop: 20,
    justifyContent: "center",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
