// referrals/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  referralCodes: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/referral_codes.json`,
  referralCodesCount: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/referral_codes/count.json`,
  referral: (referral_id) =>
    `${helper.BASE_URL}/admin/referrals/${referral_id}.json`,
};
