import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(0.5),
  },
}));
