import accounts from "./accounts/index.js";
import billing from "./billing/index.js";
import campaigns from "./campaigns/index.js";
import contacts from "./contacts/index.js";
import domainblacklist from "./domainblacklist/index.js";
import logs from "./logs/index.js";
import numberpool from "./numberpool/index.js";
import referrals from "./referrals/index.js";

export default {
  accounts,
  billing,
  campaigns,
  contacts,
  domainblacklist,
  logs,
  numberpool,
  referrals,
};
