// accounts/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  contactEmails: () => `${helper.BASE_URL}/admin/contacts/emails.json`,
};
