import React from "react";
import { useHistory } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import MenuIcon from "@material-ui/icons/Menu";
import { PowerSettingsIcon } from "components/VivialIcons";

import { useStyles } from "./styles";

export default function MainAppBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const { handleDrawerToggle } = props;

  const handleLogout = () => {
    history.push("/logout");
  };

  const { title, subtitle } = props;

  return (
    <AppBar position="absolute" className={classes.appBar} color="inherit">
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <IconButton
            aria-label="show menu"
            onClick={handleDrawerToggle}
            className={classes.drawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <div className={classes.title}>
          <Typography
            component="h1"
            variant="h4"
            noWrap
            className={classes.typography}
          >
            {title}
          </Typography>
          <Hidden smDown>
            {subtitle ? (
              <Box className={classes.box}>
                <Typography
                  component="h2"
                  variant="h6"
                  noWrap
                  className={`${classes.typography} ${classes.subTitle}`}
                >
                  {subtitle}
                </Typography>
              </Box>
            ) : null}
          </Hidden>
        </div>

        <div className={classes.menuItems}>
          <IconButton
            aria-label="Logout session"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            title="Logout"
            onClick={handleLogout}
          >
            <PowerSettingsIcon className={classes.menuIconPower} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
