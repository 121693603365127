import React from "react";
import Grid from "@material-ui/core/Grid";

import { useStyles } from "./styles";

export default function FormWrapper({ onSubmit, children }) {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.formWrapper}
    >
      <Grid item xs={12}>
        <div className={classes.form}>
          <form autoComplete="off" onSubmit={onSubmit}>
            {children}
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
