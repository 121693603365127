export default {
  APP_NAME: "caribou",
  BASE_URL: process.env.REACT_APP_BACKEND_API_URL,
  OAUTH: {
    URL: process.env.REACT_APP_OAUTH_URL,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    GRANT_TYPE: "password",
    TOKEN_TYPE_HINT: "access_token",
  },
  COOKIE: {
    TOKEN_NAME: "token",
    TOKEN_EXPIRE_IN_MINUTES: 1,
  },
};
