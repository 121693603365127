import React from "react";
import { HomeIcon, PaymentIcon, AccountTreeIcon } from "components/VivialIcons";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";

export const menuItems = [
  {
    name: "Dashboard",
    route: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    name: "Accounts",
    route: "/accounts",
    alternativeRoute: "/account/:id",
    icon: <AccountTreeIcon />,
  },
  {
    name: "Global Settings",
    route: "/settings",
    icon: <PaymentIcon />,
  },
  {
    name: "TCR",
    route: "/tcr",
    icon: <ChatOutlinedIcon />,
    children: [
      {
        optionName: "Import Campaigns",
        route: "/tcr",
      },
    ],
  },
];
