import React, { useEffect } from "react";
import { toast } from "react-toastify";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import ConfirmModal from "@moosetalk-frontend/global/components/ConfirmModal";
import { useStyles } from "./styles";
import { VivialConnectAPI } from "api/vivial-connect-admin";
const { services } = VivialConnectAPI;

export default function ContactsTable({
  handleSelectedContact,
  accountId,
  fetchContacts,
  contacts,
}) {
  const classes = useStyles();

  const handleOnCellClick = (contact) => {
    handleSelectedContact(contact);
  };

  const handleOnDelete = async (contact) => {
    services.contacts
      .deleteContact(contact.id, accountId)
      .then(() => {
        toast.success(`Contact ${contact.id} deleted`);
        fetchContacts();
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  return (
    <DashboardCard>
      <DashboardCardContent>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Type
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    First Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Last Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Contact Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Company
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Phone
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="h6"
                    className={classes.tableColumnHeader}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map((row, indx) => (
                <TableRow hover key={indx}>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleOnCellClick(row)}
                    className={classes.tableClickableCell}
                  >
                    <Typography variant="h5">{row.contact_type}</Typography>
                  </TableCell>
                  <TableCell
                    onClick={() => handleOnCellClick(row)}
                    className={classes.tableClickableCell}
                  >
                    {row.first_name}
                  </TableCell>
                  <TableCell
                    onClick={() => handleOnCellClick(row)}
                    className={classes.tableClickableCell}
                  >
                    {row.last_name}
                  </TableCell>
                  <TableCell
                    onClick={() => handleOnCellClick(row)}
                    className={classes.tableClickableCell}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    onClick={() => handleOnCellClick(row)}
                    className={classes.tableClickableCell}
                  >
                    {row.company_name}
                  </TableCell>
                  <TableCell
                    onClick={() => handleOnCellClick(row)}
                    className={classes.tableClickableCell}
                  >
                    {row.mobile_phone}
                  </TableCell>
                  <TableCell align="center">
                    <ConfirmModal
                      title={`DELETE CONTACT - ${row.email}`}
                      warningMessage={`This action will delete the Contact.`}
                      clickableComponent={
                        <IconButton>
                          <DeleteIcon className={classes.tableDeleteButton} />
                        </IconButton>
                      }
                      onSubmit={() => handleOnDelete(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DashboardCardContent>
    </DashboardCard>
  );
}
