import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getReport = async (reportId, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.report(id ? id : appSessionStore.getAccountId(), reportId)
    );
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getReports = async (id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.get(
      endpoints.reports(id ? id : appSessionStore.getAccountId())
    );
    return data.reports;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postMessageReport = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.reportMessage(id ? id : appSessionStore.getAccountId()),
      null,
      { params }
    );

    return data.report;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postTransactionReport = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.reportTransaction(id ? id : appSessionStore.getAccountId()),
      null,
      { params }
    );

    return data.report;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postCreditReport = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.reportCredit(id ? id : appSessionStore.getAccountId()),
      null,
      { params }
    );

    return data.report;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const postOptoutReport = async (params, id) => {
  const { http, appSessionStore } = serviceHelpers;
  try {
    const { data } = await http.post(
      endpoints.reportOptout(id ? id : appSessionStore.getAccountId()),
      null,
      { params }
    );

    return data.report;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getReport,
  getReports,
  postMessageReport,
  postTransactionReport,
  postCreditReport,
  postOptoutReport,
};
