/* usageChart.js
 * Component for displaying aggregated logs chart
 * caller must pass data fetch and processing methods
 */
import React, { useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { logGroups } from "./constants";
import { useStyles } from "./styles";

export default React.memo(function UsageChart(props) {
  const classes = useStyles();
  const {
    chartHeight = 500,
    chartId = "vc-usage-chart",
    filters,
    selectedLogGroup,
    getData,
    transformData,
  } = props;
  const [chartSeries, setChartSeries] = useState([]);
  const [lastAPIData, setLastAPIData] = useState({
    count: 0,
    items: [],
  });

  const chartOptions = {
    chart: {
      id: chartId,
      zoom: {
        enabled: false,
      },
    },
    colors: [
      "#24d235",
      "#3C6A89",
      "#d8e699",
      "#a5d05e",
      "#000000",
      "#04867e",
      "#58be89",
    ],
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    legend: {
      fontFamily: "Aktiv Grotesk, Arial, serif",
      fontWeight: 300,
      fontSize: "16px",
      offsetY: 10,
      markers: {
        width: 15,
        height: 15,
        radius: 15,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 10,
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM-yyyy",
          day: "dd-MMM",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return parseInt(value);
        },
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {},
      },
    ],
  };

  /* updateChartTitle
   * Update the chart title to reflect filter selections
   */
  const updateChartTitle = useCallback(() => {
    let newChartTitle = `${
      selectedLogGroup && logGroups[selectedLogGroup]
        ? logGroups[selectedLogGroup].label
        : ""
    } ${
      filters.startDate && filters.endDate
        ? ` — ${filters.startDate.toDateString()} to ${filters.endDate.toDateString()}`
        : ""
    }`;

    ApexCharts.exec(
      chartId,
      "updateOptions",
      {
        title: {
          text: newChartTitle,
          align: "left",
        },
      },
      false,
      true
    );
  }, [filters, selectedLogGroup, chartId]);

  /* updateChartData
   * Get fresh data from API
   */
  const updateChartData = async (filters) => {
    //sanity check: do not update if dates are null
    //this is possible if the 'custom' timespan option is selected
    if (!filters.startDate || !filters.endDate) return;

    const apiResponse = await getData(filters);
    setLastAPIData(apiResponse);
  };

  /* updateChartSeries
   * Transform API data to chart data
   * Filter-out unwanted log types
   * Update Chart
   */
  const updateChartSeries = () => {
    //sanity check: do not update if dates are null
    //this is possible if the 'custom' timespan option is selected
    if (!filters.startDate || !filters.endDate) return;

    const series = transformData(lastAPIData, filters, selectedLogGroup);

    if (series) {
      setChartSeries(series);

      if (!(Array.isArray(series) && series.length)) {
        ApexCharts.exec(
          chartId,
          "updateOptions",
          {
            noData: {
              text: "No activity found for selected date range and log type.",
            },
          },
          false,
          true
        );
      }
    } else {
      ApexCharts.exec(
        chartId,
        "updateOptions",
        {
          noData: {
            text: "Unable to fetch data.",
          },
        },
        false,
        true
      );
    }
  };

  useEffect(() => {
    updateChartData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    updateChartTitle();
    updateChartSeries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAPIData, selectedLogGroup]);

  return (
    <Container className={classes.root} fixed>
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid item xs={12}>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={chartHeight}
          />
        </Grid>
      </Grid>
    </Container>
  );
});
