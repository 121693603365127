import React from "react";
import { Grid, Typography } from "@material-ui/core";

import DashboardLayout from "components/Layouts/Dashboard";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import { VivialConnectAdminAPI } from "api/vivial-connect-admin";

import {
  chartStyles,
  dataHelpers,
  withLogsView,
} from "@moosetalk-frontend/global/components/UsageChart";

// fetch/process data for system charts
const { logs: adminLogsService } = VivialConnectAdminAPI.services;
const {
  getSystemFilteredChartData,
  processSystemLogDataForChart,
} = dataHelpers;

// withLogsView HOC behaves like a mixin.
// Logs component will throw errors if not wrapped in withLogsView
export default withLogsView(Dashboard);

function Dashboard({ logsView }) {
  const classes = chartStyles();

  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <logsView.activityFilters title="" />
        </Grid>
        <Grid item xs={12}>
          <DashboardCard>
            <DashboardCardContent alignContent="flex-start">
              <Grid item xs={12} className={classes.logsHeader}>
                <Typography variant="h4" className={classes.text}>
                  Aggregated Logs
                </Typography>
                <logsView.chartLogGroupSelector />
              </Grid>

              <Grid item xs={12}>
                <logsView.usageChart
                  getData={getSystemFilteredChartData(adminLogsService)}
                  transformData={processSystemLogDataForChart}
                />
              </Grid>
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
