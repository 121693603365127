import React, { useState } from "react";
import { toast } from "react-toastify";
import DashboardLayout from "components/Layouts/Dashboard";
import {
  Grid,
  FormControl,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import { useStyles } from "./styles";
import { CAMPAIGN_ORIGINS } from "./constants";

import { VivialConnectAdminAPI } from "api/vivial-connect-admin";
const { services } = VivialConnectAdminAPI;
const { validation } = VivialConnectAdminAPI.helpers;

export default function TCR() {
  const classes = useStyles();
  const [accountId, setAccountId] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [campaignOrigin, setCampaignOrigin] = useState("INTERNAL");
  const [accountError, setAccountErrors] = useState("");

  const handleAccountChange = (event) => {
    setAccountId(event.target.value);
  };

  const handleCampaignChange = (event) => {
    setCampaignId(event.target.value);
  };

  const handleOriginChange = (event) => {
    setCampaignOrigin(event.target.value);
  };

  const validateForm = () => {
    let error = false;

    if (!validation.validateNumber(accountId)) {
      error = true;
      setAccountErrors("Account ID must be a number.");
    } else {
      setAccountErrors("");
    }

    return error;
  };

  const handleSubmit = () => {
    if (validateForm()) return;
    const payload = {
      account_id: accountId,
      campaign_id: campaignId,
      origin: campaignOrigin,
    };

    services.campaigns
      .addCampaign(payload)
      .then((data) => {
        toast.success(`Campaign added to account ${accountId}`);
        setAccountId("");
        setCampaignId("");
      })
      .catch((error) => toast.error(error));
  };

  return (
    <DashboardLayout>
      <DashboardCard>
        <DashboardHeader title={"Import Campaigns"} />
        <DashboardCardContent>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
            autoComplete="off"
            className={classes.form}
          >
            <Grid container spacing={5}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    required
                    variant="outlined"
                    id="accountId"
                    label="Account ID"
                    name="accountId"
                    value={accountId}
                    onChange={handleAccountChange}
                    error={accountError !== ""}
                    helperText={
                      accountError ? accountError : "Please enter Account ID"
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    required
                    variant="outlined"
                    id="campaignId"
                    label="Campaign ID"
                    name="campaignId"
                    value={campaignId}
                    onChange={handleCampaignChange}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    select
                    variant="outlined"
                    label="Campaign Origin"
                    id="campaignOrigin"
                    name="campaignOrigin"
                    value={campaignOrigin}
                    onChange={handleOriginChange}
                  >
                    {Object.keys(CAMPAIGN_ORIGINS).map((key) => (
                      <MenuItem key={`origin-${key}`} value={key}>
                        {CAMPAIGN_ORIGINS[key]}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  fullWidth
                >
                  Create Campaign
                </Button>
              </Grid>
            </Grid>
          </form>
        </DashboardCardContent>
      </DashboardCard>
    </DashboardLayout>
  );
}
