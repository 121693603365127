import { makeStyles } from "@material-ui/core";
import { colorExtendedGreen4 } from "themes/palette";
import { red } from "@material-ui/core/colors";

export const useStyles = makeStyles({
  editYourProfileButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tableContainer: {
    maxHeight: 500,
  },
  tableColumnHeader: {
    color: colorExtendedGreen4,
  },
  tableDeleteButton: {
    color: red["A700"],
  },
  tableClickableCell: {
    cursor: "pointer",
    "& h5, & h6": {
      color: colorExtendedGreen4,
    },
  },
});
