import { makeStyles } from "@material-ui/core/styles";
import { colorExtendedGray4, colorExtendedGreen4 } from "themes/palette";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  note: {
    fontSize: "0.8em",
    color: colorExtendedGray4,
    "& > a": { fontSize: "1em" },
  },
  editYourProfileButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  bannerWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  bannerHeader: {
    color: colorExtendedGreen4,
    textTransform: "uppercase",
  },
  addButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addButton: {
    "& :hover": { color: colorExtendedGreen4 },
  },
  tableContainer: {
    maxHeight: 500,
  },
  tablePagination: {
    display: "flex",
    width: "100%",
    marginTop: 20,
    justifyContent: "center",
  },
  tableColumnHeader: {
    color: colorExtendedGreen4,
  },
  tableDeleteButton: {
    color: red["A700"],
  },
  tableClickableCell: {
    cursor: "pointer",
    "& h5, & h6": {
      color: colorExtendedGreen4,
    },
  },
  spinner: {
    position: "absolute",
  },
}));

export default useStyles;
