import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getAccounts = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accounts(), { params });
    return data.accounts;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountsCount = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountsCount(), { params });
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccount = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.account(id));
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountStatus = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountStatus(), { params });
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const setAccountActive = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.put(endpoints.accountActive(id), params);
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountTransactions = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountTransactions(id), {
      params,
    });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountStateOptions = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountStateOptions(id));
    return data.options;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const setAccountStateOptions = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.put(endpoints.accountStateOptions(id), params);
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountStopFiltering = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountStopFiltering(id));
    return data.disabled;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const setAccountStopFiltering = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.put(endpoints.accountStopFiltering(id), params);
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

//NOTE: As of 2020-12-12 the accountPlanThreshold endpoint
//doesn't appear to support GET requests. ¯\_(ツ)_/¯
const getAccountPlanThresholds = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountPlanThresholds(id));
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const setAccountPlanThresholds = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.put(endpoints.accountPlanThresholds(id), {
      params,
    });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountPhoneVerified = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountPhoneVerified(id));
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const setAccountPhoneVerified = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.put(endpoints.accountPhoneVerified(id), params);
    return data.account;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const addAccountReseller = async (id, payload = {}) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.post(endpoints.accountReseller(id), payload);
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getAccounts,
  getAccountsCount,
  getAccount,
  getAccountStatus,
  setAccountActive,
  getAccountTransactions,
  getAccountStateOptions,
  setAccountStateOptions,
  getAccountStopFiltering,
  setAccountStopFiltering,
  getAccountPlanThresholds, //not supported by API
  setAccountPlanThresholds,
  getAccountPhoneVerified,
  setAccountPhoneVerified,
  addAccountReseller,
};
