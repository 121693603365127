// NOTE: In 2021-01 I removed the "Charge CC" and "Insert Transaction" features
// from Account because they were broken. @nherman
//
// The deleted code can be found here:
// https://github.com/Localvox/moosetalk-frontend/blob/072d81f1979860ff307a9c5829c33e4c9ebc10ba/caribou/src/pages/Account/index.js
// https://github.com/Localvox/moosetalk-frontend/blob/072d81f1979860ff307a9c5829c33e4c9ebc10ba/caribou/src/pages/Account/Billing/index.js

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import TransactionsPane from "@moosetalk-frontend/global/components/TransactionsPane";

import TabContent from "components/TabPanel";

import {
  VivialConnectAPI,
  VivialConnectAdminAPI,
} from "api/vivial-connect-admin";

const { accounts } = VivialConnectAPI.services;

export default function Billing() {
  let { id: accountId } = useParams();
  const [companyName, setCompanyName] = useState("");

  const getAccountTransactions = (params) => {
    return accounts.getAccountTransactions(params, accountId);
  };

  const getAccountTransactionsCount = (params) => {
    return accounts.getAccountTransactionsCount(params, accountId);
  };

  useEffect(() => {
    async function fetchAccount() {
      accounts
        .getAccount(accountId)
        .then((account) => {
          setCompanyName(account.company_name);
        })
        .catch((error) => {
          toast.error("An error ocurred");
        });
    }
    fetchAccount();
  }, [accountId]);

  return (
    <TabContent companyName={companyName}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TransactionsPane
            title="Transaction History"
            helperService={VivialConnectAPI.helpers}
            getAccountTransactions={getAccountTransactions}
            getAccountTransactionsCount={getAccountTransactionsCount}
          />
        </Grid>
      </Grid>
    </TabContent>
  );
}
