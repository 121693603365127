import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GroupAddIcon } from "components/VivialIcons";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import useStyles from "./styles";

const AddUserBanner = ({
  bannerTitle,
  addButtonLabel,
  goBackButtonLabel,
  showGoBackButton,
  showAddButton,
  goBackButtonIcon,
  addButtonIcon,
  handleShowAddForm,
  handleShowDefault,
}) => {
  const classes = useStyles();

  return (
    <DashboardCard>
      <DashboardCardContent>
        <div className={classes.bannerWrapper}>
          <Grid item xs={3}>
            <Typography variant="h5" className={classes.bannerHeader}>
              {bannerTitle}
            </Typography>
          </Grid>
          <Grid item xs={9} className={classes.addButtonWrapper}>
            {showGoBackButton ? (
              <Button
                color="primary"
                variant="outlined"
                startIcon={goBackButtonIcon ? goBackButtonIcon : <ArrowBackIcon />}
                onClick={handleShowDefault}
              >
                {goBackButtonLabel}
              </Button>
            ) : null}
            {showAddButton ? (
              <Box ml={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={addButtonIcon ? addButtonIcon : <GroupAddIcon />}
                  onClick={handleShowAddForm}
                >
                  {addButtonLabel}
                </Button>
              </Box>
            ) : null}
          </Grid>
        </div>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export default AddUserBanner;
