/****
 * vivialconnect-js-admin-api
 * Client for Vivial Connect admin endpoints.
 * Depends on vivial-connect-js-core library
 *
 * Usage:
 *
 * import VivialConnectAdminAPI from "vivialconnect-js-admin-api";
 * const { services, helpers } = VivialConnectAdminAPI({
 *     BASE_URL: https://api.vivialconnect.net/api/v1.0
 *     OAUTH: {
 *       URL: https://api.vivialconnect.net,
 *       CLIENT_ID: "[ID]",
 *       CLIENT_SECRET: "[SECRET]",
 *     }
 * });
 *
 * services.auth.login(uname, pword);
 *
 */

import VivialConnectJSCore from "vivialconnect-js-core";
import services from "./src/services/index.js";
import helpers from "./src/helpers/index.js";

export default function VivialConnectAdminAPI(_config) {
  const core = VivialConnectJSCore(_config);

  //initialize components with core-js (config, http, session storage, etc)
  for (const [name, component] of Object.entries(services)) {
    if (typeof component.init === "function") component.init(core);
  }

  //pass through core-js services and helpers
  return {
    services: { ...services, ...core.services },
    helpers: { ...helpers, ...core.helpers },
  };
}
