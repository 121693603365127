import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getLogsAggregate = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.logsAggregate(), {
      params,
    });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountLogs = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountLogs(id), { params });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getAccountLogsAggregate = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.accountLogsAggregate(id), {
      params,
    });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getLogsAggregate,
  getAccountLogs,
  getAccountLogsAggregate,
};
