import React, { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import AccountFilter from "./Filter";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import { constants } from "@moosetalk-frontend/global/components/UsageChart";

import DashboardLayout from "components/Layouts/Dashboard";

import ListItem from "@material-ui/core/ListItem";
import ListView from "components/ListView";
import MainItem from "components/ListView/MainItem";

import { useStyles } from "./styles";
import { useMountedRef } from "../../components/helpers/customHooks";

import VivialConnectAdminAPI from "api/vivial-connect-admin";
const { services } = VivialConnectAdminAPI;

export default function Accounts(props) {
  let history = useHistory();
  const isMounted = useMountedRef();

  const limit = 10;
  const defaultFilterDates = {
    timeSpan: "none",
    startDate: null,
    endDate: null,
  };

  const [accounts, setAccounts] = useState([]);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showAccounts, setShowAccounts] = useState("all");
  const [filters, setFilters] = useState(defaultFilterDates);

  let timeout = createRef(null);
  let searchAccountRef = createRef();

  const getShowAccountValue = (selectedValue) => {
    if (selectedValue === "all") {
      return null;
    } else if (selectedValue === "active") {
      return "1";
    } else {
      return "0";
    }
  };

  const getDateFilterValue = (date) => {
    if (date !== null) {
      return date.toISOString();
    } else {
      return null;
    }
  };

  const fetchAccounts = (options = {}, timeLimit = 0) => {
    const { startDate, endDate } = filters;
    const searchAccount =
      searchAccountRef.current.value !== ""
        ? searchAccountRef.current.value
        : null; //force a null here to prevent empty search params in request

    const params = {
      page: page + 1, //Adjust by 1 because API pagintation is one-based
      limit: rowsPerPage,
      active: getShowAccountValue(showAccounts),
      search: searchAccount,
      start_date: getDateFilterValue(startDate),
      end_date: getDateFilterValue(endDate),
      ...options,
    };

    // Allow callers to reset pagination
    // Adjust by 1 because Material Design pagintation is zero-based
    if (options.page) {
      setPage(options.page - 1);
    }

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      services.accounts
        .getAccounts(params)
        .then((accounts) => {
          if (!isMounted.current) return;
          setAccounts(accounts);
        })
        .catch((error) => toast.error(error));

      services.accounts
        .getAccountsCount(params)
        .then((count) => {
          if (!isMounted.current) return;
          setCount(count);
        })
        .catch((error) => toast.error(error));
    }, timeLimit);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, filters, showAccounts]);

  // Account Active Filter
  const handleShowAccounts = (event) => {
    const selectedValue = event.target.value;
    setShowAccounts(selectedValue);
  };

  //Search Filter
  const handleSearchAccount = () => {
    fetchAccounts({ page: 1 }, 1000);
  };

  // Pagination
  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value));
    setPage(0);
  };
  // End => Pagination

  // Date filter
  const handleFiltersChange = (prop) => (event) => {
    let newFilters;
    if (prop === "timeSpan") {
      if (event.target.value === "none") {
        newFilters = defaultFilterDates;
      } else {
        const timeFilters = constants.timeSpanFilters[event.target.value];
        newFilters = {
          timeSpan: event.target.value,
          startDate: timeFilters.startDate,
          endDate: timeFilters.endDate,
        };
      }
      setFilters(newFilters);
    } else {
      if (!event || isNaN(event.valueOf())) return;
      setFilters({ ...filters, [prop]: event });
    }
  };
  //End => Date filter

  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AccountFilter
            showAccounts={showAccounts}
            handleChangeShowAccounts={handleShowAccounts}
            searchAccount={searchAccountRef}
            onSearchAccount={handleSearchAccount}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        </Grid>
        <Grid item xs={12}>
          <IndexView />
        </Grid>
      </Grid>
    </DashboardLayout>
  );

  function IndexView() {
    const classes = useStyles();

    const renderItem = (account, index) => {
      return handleAccounts(account, index);
    };

    const handleAccounts = (account, index) => {
      const { id, company_name, date_created, account_state } = account;

      return (
        <>
          <MainItem
            primaryText={id}
            secondaryText={company_name}
            itemType="first"
            key={id}
            colSpan={3}
          />
          <ListItem key={id + "date"}>{date_created}</ListItem>
        </>
      );
    };

    const ShowError = () => (
      <DashboardCard>
        <DashboardCardContent>
          <Typography variant="subtitle1">No accounts found.</Typography>
        </DashboardCardContent>
      </DashboardCard>
    );

    const handleAccountSelect = async (account) => {
      history.push(`/account/${account.id}`);
    };

    const ShowList = () => {
      const headers = ["ID", "Date Created"];
      const lastIndex = Math.min(accounts.length, rowsPerPage);
      const midpoint = Math.ceil(lastIndex / 2);

      return (
        <DashboardCard>
          <DashboardCardContent>
            <Grid item xs={12} lg={6}>
              <ListView
                headers={headers}
                list={accounts.slice(0, midpoint)}
                notFoundMessage="No accounts found."
                columns={2}
                renderItem={(row) => renderItem(row)}
                onClick={handleAccountSelect}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ListView
                headers={headers}
                hideHeaderBreakpoints={{ mdDown: true }}
                list={accounts.slice(midpoint, lastIndex)}
                columns={2}
                renderItem={(row) => renderItem(row)}
                onClick={handleAccountSelect}
              />
            </Grid>
            {count !== null && count > limit && (
              <Grid item xs={12} className={classes.paginationWrapper}>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  rowsPerPageOptions={["10", "20", "40"]}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  onPageChange={handlePageChange}
                />
              </Grid>
            )}
          </DashboardCardContent>
        </DashboardCard>
      );
    };

    return (
      <Grid item xs={12}>
        {count > 0 && <ShowList />}
        {count === 0 && <ShowError />}
      </Grid>
    );
  }
}
