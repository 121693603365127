import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getPoolNumbers = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.poolNumbers(), {
      params,
    });
    return data.phone_numbers;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const addPoolNumber = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.post(endpoints.poolNumbers(), {
      params,
    });
    return data.phone_number;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getPoolNumber = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.poolNumber(id));
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

//accepts a 'force' query string param like:
// params = {force: true}
const removePoolNumber = async (id, params) => {
  const { http } = serviceHelpers;
  try {
    return await http.delete(endpoints.poolNumber(id), { params });
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getPoolNumbers,
  addPoolNumber,
  getPoolNumber,
  removePoolNumber,
};
