import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getLookups = async (name) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.lookups(name));
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getLookupsPrices = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.lookupsPrices(), { params });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getCountries = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.contries(), { params });
    return data.country_lookup;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getStates = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.states(), { params });
    return data.state_lookup;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getContactTypes = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.contactTypes(), { params });
    return data.contact_type_lookup;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getLookups,
  getLookupsPrices,
  getCountries,
  getStates,
  getContactTypes,
};
