// logs/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  logsAggregate: () => `${helper.BASE_URL}/admin/logs/aggregate.json`,
  accountLogs: (id) => `${helper.BASE_URL}/admin/accounts/${id}/logs.json`,
  accountLogsAggregate: (id) =>
    `${helper.BASE_URL}/admin/accounts/${id}/logs/aggregate.json`,
};
