import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faAddressCard,
  faBuilding,
  faCalendarAlt,
  faChartLine,
  faCogs,
  faCoins,
  faCreditCard,
  faDollarSign,
  faEnvelope,
  faFlag,
  faHandHoldingUsd,
  faIdCard,
  faMapMarkerAlt,
  faMobile,
  faSignOutAlt,
  faUser,
  faListAlt,
  faGlobeAmericas
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAddressBook,
  faAddressCard,
  faBuilding,
  faCalendarAlt,
  faChartLine,
  faCogs,
  faCoins,
  faCreditCard,
  faDollarSign,
  faEnvelope,
  faFlag,
  faHandHoldingUsd,
  faIdCard,
  faMapMarkerAlt,
  faMobile,
  faSignOutAlt,
  faUser,
  faListAlt,
  faGlobeAmericas
);
