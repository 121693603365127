import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const addCampaign = async (payload) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.post(endpoints.campaignImport(), payload);
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  addCampaign,
};
