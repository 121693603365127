import React, { useState, useRef } from "react";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { AccountCircle, Business, LocationOn, Phone } from "@material-ui/icons";
import { useStyles } from "./styles";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
  CardHeader as DashboardCardHeader,
} from "components/Layouts/Dashboard/Card";

import { VivialConnectAPI } from "api/vivial-connect-admin";
const { services, helpers } = VivialConnectAPI;

const ContactForm = ({
  contact = {},
  handleShowDefault,
  accountId,
  lookups,
}) => {
  const BLANK_CONTACT = {
    contact_type: "",
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_phone: "",
    country: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
  };

  const formRef = useRef(null);
  const classes = useStyles();

  //HACK! HACK! HACK!
  //Our Contacts Country data is _super_ messy. As of 2021-01-13, Chital sets
  //the billing contact country to "United States of America" by default.
  if (contact.country) {
    //Find the canonical country entity that matches our contact
    const myCountry = lookups.countries.find(
      (country) =>
        contact.country === country.name || contact.country === country.code
    );

    //Force the contact to use country.code, or set contact.country null
    contact.country = myCountry !== undefined ? myCountry.code : null;
  }
  // END HACK!

  const [internalContact, setInternalContact] = useState({
    ...BLANK_CONTACT,
    ...contact,
  });

  const handleContactChange = (prop) => (event) => {
    let newContactData = { ...internalContact, [prop]: event.target.value };
    setInternalContact(newContactData);
  };

  const validate = () => {
    const { validateHasData, validateEmail } = helpers.validation;
    let isValid = true;

    if (!validateHasData(internalContact.contact_type)) {
      toast.error("Missing contact type");
      isValid = false;
    }
    if (internalContact.email !== "" && !validateEmail(internalContact.email)) {
      toast.error("Invalid email address");
      isValid = false;
    }

    return isValid;
  };

  const onContactSubmit = async () => {
    if (!validate()) return;

    if (contact.id !== undefined && contact.id !== null) {
      services.contacts
        .putContact(contact.id, { contact: internalContact }, accountId)
        .then(() => toast.success(`Contact ${contact.id} updated`))
        .catch((error) => toast.error(error));
    } else {
      services.contacts
        .postContacts({ contact: internalContact }, accountId)
        .then((newContact) => toast.success(`Contact ${newContact.id} created`))
        .catch((error) => toast.error(error));
    }
    handleShowDefault();
  };

  const renderInfo = (id, icon, title, value, onChange) => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            id={id}
            label={title}
            name={title}
            value={value !== null ? value : ""}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderSelect = (id, title, value, onChange, sources) => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="select-outlined-label">{title}</InputLabel>
          <Select
            id={id}
            label={title}
            value={value !== null ? value : ""}
            onChange={onChange}
            name={id}
          >
            {sources.map((source, i) => (
              <MenuItem key={i} value={source.code}>
                {source.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <DashboardCard>
      <DashboardCardHeader
        title={contact.id ? `Update Contact ${contact.id}` : "Add New Contact"}
      />
      {contact.id === undefined && (
        <p>
          <em>NOTE: An account can only have one contact of each type.</em>
        </p>
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onContactSubmit(internalContact);
        }}
        autoComplete="off"
        ref={formRef}
      >
        <DashboardCardContent container spacing={3}>
          {renderSelect(
            "contact-type",
            "Contact Type",
            internalContact.contact_type,
            handleContactChange("contact_type"),
            lookups.contactTypes
          )}
          <Grid item xs={12} sm={6}>
            &nbsp;
          </Grid>
          {renderInfo(
            "first_name",
            <AccountCircle />,
            "First Name",
            internalContact.first_name,
            handleContactChange("first_name")
          )}
          {renderInfo(
            "address1",
            <LocationOn />,
            "Address Line 1",
            internalContact.address1,
            handleContactChange("address1")
          )}

          {renderInfo(
            "last_name",
            <AccountCircle />,
            "Last Name",
            internalContact.last_name,
            handleContactChange("last_name")
          )}

          {renderInfo(
            "address2",
            <LocationOn />,
            "Address Line 2",
            internalContact.address2,
            handleContactChange("address2")
          )}

          {renderInfo(
            "email",
            <AccountCircle />,
            "Email Address",
            internalContact.email,
            handleContactChange("email")
          )}
          {renderInfo(
            "contact-city",
            <LocationOn />,
            "City",
            internalContact.city,
            handleContactChange("city")
          )}
          {renderInfo(
            "company_name",
            <Business />,
            "Company Name",
            internalContact.company_name,
            handleContactChange("company_name")
          )}

          {renderSelect(
            "contact-state",
            "State",
            internalContact.state,
            handleContactChange("state"),
            lookups.states
          )}

          {renderInfo(
            "phone",
            <Phone />,
            "Phone",
            internalContact.mobile_phone,
            handleContactChange("mobile_phone")
          )}
          {renderSelect(
            "contact-country",
            "Country",
            internalContact.country,
            handleContactChange("country"),
            lookups.countries
          )}
          {renderInfo(
            "postal_code",
            <LocationOn />,
            "Postal Code",
            internalContact.postal_code,
            handleContactChange("postal_code")
          )}

          <Grid item xs={12}>
            <div className={classes.editYourProfileButton}>
              <Box>
                <Button variant="contained" onClick={handleShowDefault}>
                  Cancel
                </Button>
              </Box>
              <Box ml={2}>
                <Button type="submit" color="primary" variant="contained">
                  {contact.id ? "Save Changes" : "Save"}
                </Button>
              </Box>
            </div>
          </Grid>
        </DashboardCardContent>
      </form>
    </DashboardCard>
  );
};

export default ContactForm;
