import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  TextField,
  Radio,
  Button,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { emailList } from "./helper";

import {
  Card as DashboardCard,
  CardHeader as DashboardHeader,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import MailOutlineIcon from "@material-ui/icons/MailOutline";

export default function MailList(props) {
  const classes = useStyles();
  const [type, setType] = React.useState("main");
  const [emails, setEmails] = React.useState();

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const query = { type: type };
    const emails = await emailList(query);

    setEmails(emails);
  };

  return (
    <DashboardCard>
      <DashboardHeader
        icon={MailOutlineIcon}
        title={"Generate Recipients for Email Blast"}
      />
      <DashboardCardContent alignContent="flex-start">
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Type</FormLabel>
            <RadioGroup
              aria-label="type"
              name="type"
              value={type}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="main"
                control={<Radio />}
                label="Main Contacts"
              />
              <FormControlLabel
                value="billing"
                control={<Radio />}
                label="Billing Contacts"
              />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Get emails
          </Button>
        </form>

        <TextField
          id="filled-multiline-static"
          label="Results"
          multiline
          rows="4"
          fullWidth
          defaultValue="Emails"
          className={classes.textField}
          margin="normal"
          variant="filled"
          value={emails}
          autoFocus={true}
        />
      </DashboardCardContent>
    </DashboardCard>
  );
}
