import React, { Component } from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Accounts from "./pages/Accounts";
import Details from "./pages/Account/Details";
import Billing from "./pages/Account/Billing";
import Logs from "./pages/Account/Logs";
import Settings from "./pages/Account/Settings";
import Contacts from "./pages/Account/Contacts";
import TCR from "./pages/TCR";
import GlobalSettings from "./pages/GlobalSettings";
import Login from "./pages/Login";
import Logout from "./components/Logout";
import NotFound from "./components/NotFound";
import logger from "./services/loggerService";
import "./utils/icons";

if (process.env.NODE_ENV === "production") {
  logger.init();
}

class App extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <React.Fragment>
          <ToastContainer />
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/accounts" component={Accounts} />
            <ProtectedRoute exact path="/account/:id" component={Details} />
            <ProtectedRoute
              exact
              path="/account/:id/billing"
              component={Billing}
            />
            <ProtectedRoute exact path="/account/:id/logs" component={Logs} />
            <ProtectedRoute
              exact
              path="/account/:id/settings"
              component={Settings}
            />
            <ProtectedRoute
              exact
              path="/account/:id/contacts"
              component={Contacts}
            />
            <ProtectedRoute exact path="/tcr" component={TCR} />
            <ProtectedRoute exact path="/settings" component={GlobalSettings} />
            <Route component={NotFound} />
          </Switch>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
