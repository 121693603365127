import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(5),
  },
}));
