// billing/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  chargeAccount: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/charge.json`,
  paymentSources: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/payment_sources.json`,
};
