import React, { useState } from "react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import LobbyLayout from "components/Layouts/Lobby";
import LobbyHeader from "components/Layouts/Lobby/Header";
import LobbyFormWrapper from "components/Layouts/Lobby/FormWrapper";
import { useStyles } from "./styles";

import VivialConnectAdminAPI from "api/vivial-connect-admin";

const ADMIN_ROLES = ["SiteAdministrator"];
const version = process.env.REACT_APP_VERSION;

export default function Login() {
	const { auth } = VivialConnectAdminAPI.services;
	const classes = useStyles();
	const [login, setLogin] = useState({
		username: "",
		password: "",
		isLogged: VivialConnectAdminAPI.helpers.token.get(),
	});

	const handleSubmit = (event) => {
		event.preventDefault();

		auth
			.login(login.username, login.password)
			.then(async (res) => {
				if (!isAdmin(res.scope)) {
					//If user does not have admin scope then logout and throw error.
					//User remains on login page
					auth
						.logout()
						.then(() =>
							toast.error("You do not have permission to access this site")
						)
						.catch((error) => toast.error(error));
				} else {
					//otherwise, set isLogged flag to true to redirect to Dashboard
					setLogin((prev) => ({
						...prev,
						isLogged: true,
					}));
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	const isAdmin = (userRoles = []) => {
		const userAdminRoles = ADMIN_ROLES.filter((role) =>
			userRoles.includes(role)
		);
		if (userAdminRoles.length) return true;
		return false;
	};

	const handleChange = ({ currentTarget: input }) => {
		login[input.name] = input.value;
		const { username, password } = login;

		setLogin((prev) => ({
			...prev,
			username: username,
			password: password,
		}));
	};

	if (login.isLogged) return <Redirect to="/dashboard" />;
	return (
		<LobbyLayout>
			<LobbyHeader title={"Admin"} />
			<LobbyFormWrapper onSubmit={handleSubmit}>
				<FormControl fullWidth>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						value={login.username}
						onChange={handleChange}
						autoFocus
						autoComplete="username"
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="password"
						value={login.password}
						onChange={handleChange}
					/>
				</FormControl>
				<div className={classes.buttonWrapper}>
					<Box mr={2}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							size="large"
						>
							Login
						</Button>
					</Box>
				</div>
			</LobbyFormWrapper>
			<div className={classes.version}>{version}</div>
		</LobbyLayout>
	);
}
