import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { format, parseISO } from "date-fns";

import useStyles from "./styles";

import { VivialConnectAdminAPI } from "api/vivial-connect-admin";

export default function ReferralsTable({ accountId }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [referrals, setReferrals] = useState([]);
  const [referralsCount, setReferralsCount] = useState(0);
  const [copyRow, setCopyRow] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleCheckboxChange = (event) => {
    const { referrals: referralEndpoints } = VivialConnectAdminAPI.services;
    const { checked, name } = event.target;
    const [checkbox, index] = name.split("-");
    const referralsCopy = [...referrals];

    referralsCopy[index][checkbox] = checked;

    const params = {
      referral_code: {
        [checkbox]: checked,
      },
    };

    referralEndpoints
      .setReferralStates(referrals[index].id, params)
      .then((updatedReferral) => {
        setReferrals(referralsCopy);
        toast.success(`Referral code ${updatedReferral.code} updated.`);
      })
      .catch((error) => toast.error(error));
  };

  const toggleAllCheckboxes = (index) => {
    const { referrals: referralEndpoints } = VivialConnectAdminAPI.services;
    const referralsCopy = [...referrals];
    let referralCode = referralsCopy[index];
    let value = !referralsCopy[index].active;

    referralCode.active = value;
    referralCode.public = value;
    referralCode.visible = value;
    referralsCopy[index] = referralCode;

    const params = {
      referral_code: {
        active: value,
        public: value,
        visible: value,
      },
    };

    referralEndpoints
      .setReferralStates(referralCode.id, params)
      .then((updatedReferral) => {
        setReferrals(referralsCopy);
        toast.success(`Referral code ${updatedReferral.code} updated.`);
      })
      .catch((error) => toast.error(error));
  };

  const copyToClipboard = (event, code) => {
    setCopyRow(code);
    navigator.clipboard.writeText(code);
  };

  const handleGetAccountReferrals = useCallback(() => {
    const { referrals } = VivialConnectAdminAPI.services;

    referrals
      .getReferralCodes(accountId)
      .then((referralCodes) => {
        setReferrals(referralCodes);
      })
      .catch((error) => toast.error(error));

    referrals
      .getReferralCodesCount(accountId)
      .then((count) => {
        setReferralsCount(count);
      })
      .catch((error) => toast.error(error));
  }, [accountId]);

  useEffect(() => {
    handleGetAccountReferrals();
  }, [handleGetAccountReferrals]);

  return (
    <>
      <dl>
        <dt>
          <b>active</b>
        </dt>
        <dd>
          Admin-only flag to enable/disable code. If 'false', overrides all
          other flags
        </dd>
        <dt>
          <b>visible</b>
        </dt>
        <dd>
          If 'true' allow code owner to view/manage this code via the API/UI.
        </dd>
        <dt>
          <b>public</b>
        </dt>
        <dd>
          The only flag the code owner can set. Allows code owner to
          endable/disable code.
        </dd>
      </dl>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="h6" color="primary">
                  Code
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" color="primary">
                  Date Created
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" color="primary">
                  Date Modified
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" color="primary">
                  Active
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" color="primary">
                  Public
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" color="primary">
                  Visible
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h6" color="primary">
                  Copy
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  onClick={() => toggleAllCheckboxes(index)}
                >
                  {row.code}
                </TableCell>
                <TableCell align="center">
                  {row.date_created
                    ? format(parseISO(row.date_created), "yyyy-MM-dd")
                    : ""}
                </TableCell>
                <TableCell align="center">
                  {row.date_modified
                    ? format(parseISO(row.date_modified), "yyyy-MM-dd")
                    : ""}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.active}
                    onChange={handleCheckboxChange}
                    name={`active-${index}`}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.public}
                    onChange={handleCheckboxChange}
                    name={`public-${index}`}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.visible}
                    onChange={handleCheckboxChange}
                    name={`visible-${index}`}
                  />
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={copyRow === row.code ? "Copied!" : "Copy Code"}
                  >
                    <IconButton onClick={(e) => copyToClipboard(e, row.code)}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 150]}
        component="div"
        count={referralsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
        labelRowsPerPage={"Rows Per Page"}
      />
    </>
  );
}
