import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";
import TabContent from "components/TabPanel";

import {
  chartStyles,
  dataHelpers,
  withLogsView,
} from "@moosetalk-frontend/global/components/UsageChart";

import {
  VivialConnectAPI,
  VivialConnectAdminAPI,
} from "api/vivial-connect-admin";

// fetch/process data for account charts
const {
  getAccountFilteredChartData,
  processAccountLogDataForChart,
} = dataHelpers;

// withLogsView HOC behaves like a mixin.
// Logs component will throw errors if not wrapped in withLogsView
export default withLogsView(Logs);

function Logs({ logsView }) {
  let { id: accountId } = useParams();
  const { logs: publicLogsService } = VivialConnectAPI.services;
  const classes = chartStyles();
  const [companyName, setCompanyName] = useState("");

  // initialize data fetch method with the current account id
  // this method gets data for the chart only
  const getDataForAccount = getAccountFilteredChartData(
    publicLogsService,
    accountId
  );

  // Control chart vs. list display
  const [showChart, setShowChart] = useState(true);
  const handleViewChange = (view) => {
    view === "chart" ? setShowChart(() => true) : setShowChart(() => false);
  };

  useEffect(() => {
    async function fetchAccount() {
      const { accounts } = VivialConnectAdminAPI.services;
      accounts
        .getAccount(accountId)
        .then((account) => {
          setCompanyName(account.company_name);
        })
        .catch((error) => {
          toast.error("An error ocurred");
        });
    }
    fetchAccount();
  }, [accountId]);

  return (
    <TabContent companyName={companyName}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <logsView.activityFilters title="" />
        </Grid>
        <Grid item xs={12}>
          <DashboardCard>
            <DashboardCardContent alignContent="flex-start">
              <Grid item xs={12} className={classes.logsHeader}>
                <ButtonGroup
                  variant="text"
                  color="primary"
                  aria-label="text primary button group"
                >
                  <Button onClick={() => handleViewChange("chart")}>
                    Usage
                  </Button>
                  <Button onClick={() => handleViewChange("logs")}>Logs</Button>
                </ButtonGroup>
                {showChart ? (
                  <logsView.chartLogGroupSelector />
                ) : (
                  <logsView.listLogGroupSelector />
                )}
              </Grid>

              <Grid item xs={12}>
                {showChart ? (
                  <logsView.usageChart
                    getData={getDataForAccount}
                    transformData={processAccountLogDataForChart}
                  />
                ) : (
                  <logsView.logsTable
                    publicAPIServices={VivialConnectAPI.services}
                    accountId={accountId}
                  />
                )}
              </Grid>
            </DashboardCardContent>
          </DashboardCard>
        </Grid>
      </Grid>
    </TabContent>
  );
}
