export const defaultAccount = {
  billing: {
    active: true,
    cash_balance: 0,
    subscription_balance: 0,
    usage_credits: 0,
    numbers_owned: 0,
    number_credits: 0,
    options: {},
    plan_info: {
      limits: {
        autobill_amount_default: 0,
        autobill_threshold_default: 0,
        cash_overage_threshold_default: 0,
        notification_threshold_default: 0,
      },
    },
    prices: {
      plan: {},
    },
    next_renewal_date: "",
    next_allocation_date: "",
  },
  contacts: [
    {
      first_name: "",
      last_name: "",
      email: "",
      mobile_phone: "",
      company_name: "",
      date_created: "",
    },
  ],
};
