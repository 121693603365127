import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getContactEmails = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.contactEmails(), {
      params,
    });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getContactEmails,
};
