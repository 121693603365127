/* displayHelpers.js
 * Filter management components necessary for chart and log list views
 */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { differenceInDays } from "date-fns";
import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

import constants from "./constants";
import LogsTable from "./logsTable";
import UsageChart from "./usageChart";
import useStyles from "./styles";

export const LogGroupSelector = ({
  selectedLogGroup,
  setSelectedLogGroup,
  logSet = "logGroups", //expects 'logGroups' or 'logTypes'
  label = "Filter",
}) => {
  const classes = useStyles();
  return (
    <TextField
      margin="normal"
      id="logs-filter"
      label={label}
      select
      value={selectedLogGroup}
      onChange={(event) => setSelectedLogGroup(event.target.value)}
      size="medium"
      InputProps={{ className: classes.textField }}
    >
      {Object.keys(constants[logSet]).map((key, i) => (
        <MenuItem value={key} key={i}>
          {typeof constants[logSet][key] === "object"
            ? constants[logSet][key].label
            : constants[logSet][key]}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const ActivityFilters = ({
  selectedTimeSpanFilter,
  selectedTimeSpanOption,
  handleTimeSpanOptionChange,
  handleDateFilterChange,
  forceRefresh,
  title = "recent account activity",
}) => {
  const classes = useStyles();

  return (
    <DashboardCard>
      <DashboardCardContent justify="space-between" alignItems="center">
        <Hidden smDown>
          <Grid item md={12} lg={4}>
            <Typography
              variant="h5"
              className={`${classes.text} ${classes.upperText}`}
            >
              {title}
            </Typography>
          </Grid>
        </Hidden>
        <Grid item md={12} lg={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2} alignItems="center">
              <Hidden mdDown>
                <Grid item xs={1}></Grid>
              </Hidden>
              <Grid item sm={4} md={3}>
                <TextField
                  variant="outlined"
                  id="logs-timespan"
                  label="Timespan"
                  defaultValue="last_seven"
                  select
                  value={selectedTimeSpanOption}
                  onChange={handleTimeSpanOptionChange}
                  fullWidth
                >
                  {Object.keys(constants.timeSpanOptions).map((key, i) => (
                    <MenuItem value={key} key={i}>
                      {constants.timeSpanOptions[key]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4} md={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="logs-start-date"
                  label="Start Date"
                  autoOk={true}
                  value={selectedTimeSpanFilter.startDate}
                  onChange={handleDateFilterChange("startDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item sm={4} md={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="logs-end-date"
                  label="End Date"
                  autoOk={true}
                  value={selectedTimeSpanFilter.endDate}
                  onChange={handleDateFilterChange("endDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={forceRefresh}
                >
                  <SearchIcon className={classes.text} /> Search
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

export const withLogsView = (Component) => (props) => {
  const defaultTimeSpan = "last_seven";
  // selectedLogGroup: log group selector for chart view
  const [selectedLogGroup, setSelectedLogGroup] = useState("all");

  // selectedLogType: log type selector for log list view
  const [selectedLogType, setSelectedLogType] = useState("message.queued");

  const [selectedTimeSpanOption, setSelectedTimeSpanOption] = useState(
    defaultTimeSpan
  );

  const [selectedTimeSpanFilter, setSelectedTimeSpanFilter] = useState({
    ...constants.timeSpanFilters[defaultTimeSpan],
  });

  // Handle changes to the timespam selector. Selected value should equal
  // one of the timespans defined in constants.js (e.g. "last_week")
  const handleTimeSpanOptionChange = (event) => {
    let newTimeSpanFilter = constants.timeSpanFilters[event.target.value];

    setSelectedTimeSpanFilter({
      ...newTimeSpanFilter,
    });

    setSelectedTimeSpanOption(event.target.value);
  };

  // NOTE: handleDateFilterChange gets called by KeyboardDatePicker when the
  // user manually selects a custom date range. KeyboardDatePicker returns a
  // date object (not an event object)
  const handleDateFilterChange = (prop) => (newDate) => {
    if (!newDate || isNaN(newDate.valueOf())) return;

    // changing the date range manually, implies a 'custom' timespan. Select
    // the apppropiate aggregatorType for the range.
    const aggregatorType =
      Math.abs(
        differenceInDays(
          newDate,
          selectedTimeSpanFilter[prop === "endDate" ? "startDate" : "endDate"]
        )
      ) > 90
        ? "months"
        : "days";

    setSelectedTimeSpanFilter({
      ...selectedTimeSpanFilter,
      aggregatorType: aggregatorType,
      [prop]: newDate,
    });

    // updates the UI to display 'custom' timeSpan label
    setSelectedTimeSpanOption("custom");
  };

  // forces a refresh if the users clicks the search button without changing
  // any filters. As of 2021-01-04 this isn't working perfectly.
  const forceRefresh = () => {
    setSelectedTimeSpanFilter({
      ...selectedTimeSpanFilter,
    });
  };

  // Display date range selectors
  const activityFilters = (props) => (
    <ActivityFilters
      selectedTimeSpanFilter={selectedTimeSpanFilter}
      selectedTimeSpanOption={selectedTimeSpanOption}
      handleTimeSpanOptionChange={handleTimeSpanOptionChange}
      handleDateFilterChange={handleDateFilterChange}
      forceRefresh={forceRefresh}
      {...props}
    />
  );

  // Display log group selector for chart view
  const chartLogGroupSelector = (props) => (
    <LogGroupSelector
      selectedLogGroup={selectedLogGroup}
      setSelectedLogGroup={setSelectedLogGroup}
      logSet="logGroups"
      {...props}
    />
  );

  // Display log type selector for logs list view
  const listLogGroupSelector = (props) => (
    <LogGroupSelector
      selectedLogGroup={selectedLogType}
      setSelectedLogGroup={setSelectedLogType}
      logSet="logTypes"
      {...props}
    />
  );

  // Display the chart view
  const usageChart = (props) => (
    <UsageChart
      filters={selectedTimeSpanFilter}
      selectedLogGroup={selectedLogGroup}
      {...props}
    />
  );

  // Display the logs list view
  const logsTable = (props) => (
    <LogsTable
      filters={selectedTimeSpanFilter}
      logType={selectedLogType}
      {...props}
    />
  );

  const withLogsViewAPI = {
    activityFilters,
    chartLogGroupSelector,
    listLogGroupSelector,
    usageChart,
    logsTable,
  };

  return <Component logsView={withLogsViewAPI} {...props} />;
};

export default {
  ActivityFilters,
  LogGroupSelector,
  withLogsView,
};
