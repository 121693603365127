import { makeStyles } from "@material-ui/core";
import { colorExtendedGreen4 } from "themes/palette";

export const useStyles = makeStyles(theme => ({
  blacklist: {
    maxHeight: 640,
    overflow: "auto",
    marginTop: 16,
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto",
    paddingTop: 16
  },
  button: {
    borderStyle: "solid",
    borderColor: "#3f51b5",
    borderWidth: "thin",
  },
  endAdorment: {
    paddingRight: 5
  },
  text: {
    color: colorExtendedGreen4,
  },
}));
