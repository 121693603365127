import { colorPrimaryWhite, colorPrimaryBlack } from "../palette";

export const lightTheme = {
  colorPrimary: {
    color: colorPrimaryWhite,
    backgroundColor: colorPrimaryBlack,
  },
};

export default {
  lightTheme,
};
