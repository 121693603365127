import React from "react";
import { Grid } from "@material-ui/core";
import DomainBlacklist from "components/DomainBlacklist";
import DashboardLayout from "components/Layouts/Dashboard";
import MailList from "../../components/MailList";

export default function GlobalSettings(props) {
  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={12}>
          <DomainBlacklist />
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={12}>
          <MailList />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
