import React, { useState, useEffect } from "react";
import ReactJson from "react-json-view";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  Grid,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  AccountCircle,
  Mail,
  Smartphone,
  Business,
  MonetizationOn,
  Subscriptions,
  CreditCard,
  PermContactCalendar,
  Redeem,
  Public,
  List,
  AttachMoney,
  Description,
  CalendarToday,
} from "@material-ui/icons";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
  CardHeader as DashboardCardHeader,
} from "components/Layouts/Dashboard/Card";
import TabContent from "components/TabPanel";
import { defaultAccount } from "../constants";
import { updateThresholds } from "./helper";
import VivialConnectAdminAPI from "api/vivial-connect-admin";

export default function Details() {
  let { id: accountId } = useParams();
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);
  const [mainContact, setMainContact] = useState(defaultAccount.contacts[0]);

  const [threshold, setThreshold] = useState({
    autobill_status: false,
    autobill_amount: 0,
    autobill_threshold: 0,
    cash_overage_threshold: 0,
    notification_threshold: 0,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (prop) => (event) => {
    setThreshold({ ...threshold, [prop]: event.target.value });
    updateThresholds(selectedAccount.id, threshold);
  };

  useEffect(() => {
    async function fetchAccount() {
      const { accounts } = VivialConnectAdminAPI.services;
      accounts
        .getAccount(accountId)
        .then((account) => {
          setSelectedAccount(account);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error ocurred");
        });
    }
    fetchAccount();
  }, [accountId]);

  useEffect(() => {
    const { contacts, billing } = selectedAccount;
    const {
      autobill_amount_default,
      autobill_threshold_default,
      cash_overage_threshold_default,
      notification_threshold_default,
    } = billing.plan_info.limits;

    setThreshold({
      autobill_status: autobill_amount_default === 0 ? false : true,
      autobill_amount: autobill_amount_default,
      autobill_threshold: autobill_threshold_default,
      cash_overage_threshold: cash_overage_threshold_default,
      notification_threshold: notification_threshold_default,
    });

    if (contacts.length > 0) {
      const main_contact = contacts
        .filter((contact) => contact.contact_type === "main")
        .pop();
      if (main_contact) {
        setMainContact(main_contact);
      }
    }

    // The api should return options always even when empty
    if (!("options" in billing.plan_info)) {
      billing.plan_info["options"] = {};
    }
    if (!("options" in billing)) {
      billing["options"] = {};
    }
  }, [selectedAccount]);

  return (
    <TabContent companyName={selectedAccount.company_name}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {renderAccountState(
            selectedAccount.billing.cash_balance / 10000,
            selectedAccount.billing.subscription_balance / 10000,
            selectedAccount.billing.usage_credits || 0,
            selectedAccount.billing.numbers_owned,
            selectedAccount.billing.number_credits || 0,
            selectedAccount.billing.options
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderPlan(
            selectedAccount.billing.plan_info,
            selectedAccount.billing.prices.plan / 10000,
            selectedAccount.billing.next_renewal_date,
            selectedAccount.billing.next_allocation_date,
            threshold,
            handleChange
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderRegistrationInfo(
            mainContact.first_name + " " + mainContact.last_name,
            mainContact.email,
            mainContact.mobile_phone,
            mainContact.company_name,
            mainContact.date_created
              ? format(parseISO(mainContact.date_created), "MMM dd, yyyy")
              : ""
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderJsonRaw(selectedAccount, openDialog, setOpenDialog)}
        </Grid>
      </Grid>
    </TabContent>
  );
}

const renderInfo = (id, icon, title, value = "", onChange, disabled = true) => {
  value = value === null ? "" : value;
  return (
    <TextField
      style={{
        marginBottom: 6,
      }}
      id={id}
      label={title}
      fullWidth
      value={value}
      onChange={onChange}
      variant="filled"
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{icon}</InputAdornment>
        ),
      }}
    />
  );
};

const renderSwitch = (label, checked, onChange, value, style) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} value={value} />}
      label={label}
      style={style}
    />
  );
};

const renderRegistrationInfo = (name, email, phone, company, dateCreated) => {
  return (
    <DashboardCard>
      <DashboardCardHeader title="Registration" />
      <DashboardCardContent block={true}>
        {renderInfo("name", <AccountCircle />, "Full Name", name)}
        {renderInfo("register_email", <Mail />, "Email", email)}
        {renderInfo("register_phone", <Smartphone />, "Phone Number", phone)}
        {renderInfo("company", <Business />, "Company", company)}
        {renderInfo(
          "date_created",
          <CalendarToday />,
          "Date Created",
          dateCreated
        )}
      </DashboardCardContent>
    </DashboardCard>
  );
};

const renderAccountState = (
  cash_balance,
  subscription_balance,
  usage_credits,
  numbers_owned,
  number_credits,
  options
) => {
  let disable_international = false;

  if ("disable_international" in options) {
    disable_international = options.disable_international;
  }

  return (
    <DashboardCard>
      <DashboardCardHeader title="Account State" />
      <DashboardCardContent block={true}>
        {renderInfo(
          "cash_balance",
          <MonetizationOn />,
          "Cash Balance",
          "$" + cash_balance
        )}
        {renderInfo(
          "subscription_balance",
          <Subscriptions />,
          "Subscription",
          subscription_balance
        )}
        {renderInfo("usage_credits", <CreditCard />, "Credits", usage_credits)}
        {renderInfo(
          "numbers_owned",
          <PermContactCalendar />,
          "Numbers owned",
          numbers_owned
        )}
        {renderInfo(
          "number_credits",
          <Redeem />,
          "Number credits",
          number_credits
        )}
        {renderInfo(
          "disable_international",
          <Public />,
          "International",
          (!disable_international).toString()
        )}
      </DashboardCardContent>
    </DashboardCard>
  );
};

const renderPlan = (
  planInfo,
  price,
  nextRenewal,
  nextAlloc,
  threshold,
  onChange
) => {
  const renderOnDemand = () => {
    return (
      <>
        {renderSwitch(
          // "autobill_status",
          "Autobill Status",
          threshold.autobill_status,
          () => onChange("autobill_status"),
          "autobill_status"
        )}
        {renderInfo(
          "autobill_amount",
          <CreditCard />,
          "Autobill Amount",
          threshold.autobill_amount,
          () => onChange("autobill_amount"),
          false
        )}
        {renderInfo(
          "autobill_threshold",
          <CreditCard />,
          "Autobill Threshold",
          threshold.autobill_threshold,
          () => onChange("autobill_threshold"),
          false
        )}
      </>
    );
  };

  const renderSubscription = () => {
    return (
      <>
        {renderInfo("price", <AttachMoney />, "Price", "$" + price)}
        {renderInfo(
          "credit_grant",
          <CreditCard />,
          "Initial Usage Credit Grant",
          planInfo.initial_grants.usage_credits || 0
        )}
        {renderInfo(
          "number_grant",
          <PermContactCalendar />,
          "Initial Number Credit Grant",
          planInfo.initial_grants.number_credits || 0
        )}
        {renderInfo(
          "autobill_amount",
          <CreditCard />,
          "Autobill Amount",
          threshold.autobill_amount,
          () => onChange("autobill_amount"),
          false
        )}
        {renderInfo(
          "autobill_threshold",
          <CreditCard />,
          "Autobill Threshold",
          threshold.autobill_threshold,
          () => onChange("autobill_threshold"),
          false
        )}

        {renderInfo(
          "renewal_type",
          <PermContactCalendar />,
          "Renewal Period",
          planInfo.dates.renewal_type
        )}
        {renderInfo(
          "alloc_type",
          <PermContactCalendar />,
          "Allocation Period",
          planInfo.dates.alloc_type
        )}
        {renderInfo(
          "next_renewal",
          <PermContactCalendar />,
          "Next Renewal Date",
          nextRenewal
        )}
        {renderInfo(
          "next_alloc",
          <PermContactCalendar />,
          "Next Allocation Date",
          nextAlloc
        )}
      </>
    );
  };

  return (
    <DashboardCard>
      <DashboardCardHeader title="Account Plan" />
      <DashboardCardContent block={true}>
        {renderInfo("plan_type", <List />, "Type", planInfo.type)}
        {planInfo.type === "ondemand" && renderOnDemand()}
        {planInfo.type === "subscription" && renderSubscription()}
      </DashboardCardContent>
    </DashboardCard>
  );
};

const renderJsonRaw = (account, openDialog, setOpenDialog) => {
  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<Description />}
        onClick={() => setOpenDialog(true)}
        fullWidth
      >
        View Raw Status Object
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="account-raw-dialog"
        // className={classes.dialog}
      >
        <DialogTitle id="account-raw-dialog">Raw Account Status</DialogTitle>
        <DialogContent>
          <ReactJson src={account} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
