import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const charge = async (id, params) => {
  const { http } = serviceHelpers;
  params.payment_type = params.payment_type || "MANUAL_PAYMENT";
  try {
    const { data } = await http.post(endpoints.chargeAccount(id), {
      params,
    });
    return data;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getPaymentSources = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.paymentSources(id));
    return data.payment_sources;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  charge,
  getPaymentSources,
};
