import VivialConnectAdminAPI from "api/vivial-connect-admin";
const { accounts } = VivialConnectAdminAPI.services;

export const planThresholds = async (accountId) => {
  const { data } = await accounts.getAccountPlanThresholds(accountId);
  return data;
};

export const updateThresholds = async (accountId, payload) => {
  const { data } = accounts.setAccountPlanThresholds(accountId, payload);
  return data;
};
