import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";

import ContactsTable from "./ContactsTable";
import ContactForm from "./ContactForm";
import AddEditBanner from "components/AddEditBanner";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GroupAddIcon } from "components/VivialIcons";
import TabContent from "components/TabPanel";

import { VivialConnectAPI } from "api/vivial-connect-admin";
const { services } = VivialConnectAPI;

export default function Contacts() {
  let { id: accountId } = useParams();
  const [showPane, setShowPane] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [contacts, setContacts] = useState([]);

  const handleShowAdd = () => {
    setShowPane("addContactsForm");
  };

  const handleShowEdit = () => {
    setShowPane("editContactsForm");
  };

  const handleShowDefault = () => {
    fetchLookups();
    fetchContacts();
    setShowPane("");
  };

  const handleSelectedContact = (selectedContact) => {
    setSelectedContact(selectedContact);
    handleShowEdit();
  };

  const [lookups, setLookups] = useState({
    contactTypes: [],
    countries: [],
    states: [],
  });

  useEffect(() => {
    async function fetchAccount() {
      const { accounts } = services;
      accounts
        .getAccount(accountId)
        .then((account) => {
          setCompanyName(account.company_name);
        })
        .catch((error) => {
          toast.error("An error ocurred");
        });
    }

    fetchAccount();
    fetchLookups();
  }, [accountId]);

  async function fetchLookups() {
    let [contactTypesList, countriesList, statesList] = await Promise.all([
      services.lookups.getContactTypes(),
      services.lookups.getCountries(),
      services.lookups.getStates(),
    ]);

    if (contactTypesList && countriesList && statesList) {
      setLookups((oldLookup) => {
        const newLookup = { ...oldLookup };
        newLookup.contactTypes = contactTypesList;
        newLookup.countries = countriesList;
        newLookup.states = statesList;
        return newLookup;
      });
    }
  }

  const fetchContacts = useCallback(() => {
    services.contacts
      .getContacts({}, accountId)
      .then((contacts) => setContacts(contacts))
      .catch((error) => toast.error(error));
  }, [accountId]);

  return (
    <TabContent companyName={companyName}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <AddEditBanner
            bannerTitle="Contacts"
            addButtonLabel="Add Contact"
            goBackButtonLabel="Manage Contacts"
            showGoBackButton={showPane !== ""}
            showAddButton={showPane !== "addContactsForm"}
            addButtonIcon={<GroupAddIcon />}
            goBackButtonIcon={<ArrowBackIcon />}
            handleShowDefault={handleShowDefault}
            handleShowAddForm={handleShowAdd}
          />
        </Grid>

        {showPane === "addContactsForm" && (
          <Grid item xs={12}>
            <ContactForm
              accountId={accountId}
              handleShowDefault={handleShowDefault}
              lookups={lookups}
            />
          </Grid>
        )}

        {showPane === "editContactsForm" && (
          <Grid item xs={12}>
            <ContactForm
              accountId={accountId}
              contact={selectedContact}
              handleShowDefault={handleShowDefault}
              lookups={lookups}
            />
          </Grid>
        )}

        {showPane === "" && (
          <Grid item xs={12}>
            <ContactsTable
              accountId={accountId}
              handleSelectedContact={handleSelectedContact}
              contacts={contacts}
              fetchContacts={fetchContacts}
            />
          </Grid>
        )}
      </Grid>
    </TabContent>
  );
}
