import { makeStyles } from "@material-ui/core";
import LobbyBackgroundImage from "assets/img/caribou.jpg";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "auto",
  },
  connectLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(4),
    maxWidth: "520px",
  },
  gridRight: {
    position: "relative",
    backgroundColor: theme.palette.common.black,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justify: "center",
    marginBottom: theme.spacing(12),
  },
  formWrapper: {
    maxWidth: "520px",
  },
  form: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 4),
    },
  },
  image: {
    backgroundImage: `url(${LobbyBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "40%",
  },
  logoImg: {
    width: "100%",
  },
  notchedOutline: {
    borderWidth: 1,
    borderColor: theme.palette.common.white,
  },
  submit: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1, 3, 1, 3),
  },
  title: {
    fontSize: "3rem",
    textAlign: "center",
    margin: theme.spacing(8, 0, 1, 0),
    fontWeight: 400,
  },
  subtitle: {
    fontSize: "1rem",
    textAlign: "justify",
    margin: theme.spacing(0, 6),
    maxWidth: "490px",
  },
  register: {
    marginLeft: 15,
  },
  link: {
    marginTop: 15,
    textDecoration: "none",
  },
  copyright: {
    position: "absolute",
    bottom: 0,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
}));
