// NOTE: commented-out code in this file supports searching by Account Create
// date. This is failing for some unknown reason. I'm leaving the code intact
// becuase this issue should be resolved soon. 2021-01-17 @nherman
// https://localvox.atlassian.net/browse/GATE-4868

import React from "react";
import {
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import { timeSpanOptions } from ""@moosetalk-frontend/global/components/UsageChart/constants";
// import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";

import {
  Card as DashboardCard,
  CardContent as DashboardCardContent,
} from "components/Layouts/Dashboard/Card";

const AccountsFilter = (props) => {
  const {
    showAccounts,
    handleChangeShowAccounts,
    searchAccount,
    onSearchAccount,
    // filters,
    // handleFiltersChange,
  } = props;

  return (
    <DashboardCard>
      <DashboardCardContent justify="space-between" alignItems="center">
        <Grid item xs={12} sm={7}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="accountsSearch"
              label="Enter Account ID or Company Name"
              onChange={onSearchAccount}
              variant="outlined"
              inputProps={{ ref: searchAccount }}
              fullWidth
              name="searchAccount"
              data-testid="textField-testid"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="showAccounts"
              value={showAccounts}
              onChange={handleChangeShowAccounts}
            >
              <FormControlLabel
                control={<Radio checked={showAccounts === "all"} />}
                label="All"
                value="all"
              />
              <FormControlLabel
                control={<Radio checked={showAccounts === "active"} />}
                label="Active"
                value="active"
              />
              <FormControlLabel
                control={<Radio checked={showAccounts === "inactive"} />}
                label="Inactive"
                value="inactive"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/*
          <Grid item md={12} lg={12}>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2} alignItems="center">
                <Grid item sm={6} md={3}>
                  <TextField
                    variant="outlined"
                    id="logs-timespan"
                    name=""
                    label="Created Date"
                    defaultValue="last_seven"
                    select
                    value={filters.timeSpan}
                    onChange={handleFiltersChange("timeSpan")}
                    fullWidth
                  >
                    <MenuItem value="none" key="none">
                      None
                    </MenuItem>
                    {Object.keys(timeSpanOptions).map((key, i) => (
                      <MenuItem value={key} key={i}>
                        {timeSpanOptions[key]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={8} md={4}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="logs-start-date"
                    label="Start Date"
                    aria-label="start date"
                    autoOk={true}
                    value={filters.startDate}
                    onChange={handleFiltersChange("startDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item sm={8} md={4}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="logs-end-date"
                    label="End Date"
                    aria-label="end date"
                    autoOk={true}
                    value={filters.endDate}
                    onChange={handleFiltersChange("endDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        */}
      </DashboardCardContent>
    </DashboardCard>
  );
};

AccountsFilter.propTypes = {
  showAccounts: PropTypes.string,
  handleChangeShowAccounts: PropTypes.func.isRequired,
  searchAccount: PropTypes.object,
  onSearchAccount: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    timeSpan: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  handleFiltersChange: PropTypes.func.isRequired,
};

export default AccountsFilter;
