// logs/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  poolNumbers: () => `${helper.BASE_URL}/admin/number_pool.json`,
  poolNumber: (id) => `${helper.BASE_URL}/admin/number_pool/${id}.json`,
};
