import endpoints from "./endpoints.js";
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//serviceHelpers == {init, http, appSessionStore}
const serviceHelpers = factory.initService(endpoints);

const getDomainBlacklist = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.domainBlacklist(), {
      params,
    });
    return data.blacklist_domains;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getDomainBlacklistCount = async (params) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.domainBlacklistCount(), {
      params,
    });
    return data.count;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};
const addDomain = async (domain) => {
  const { http } = serviceHelpers;
  const payload = {
    blacklist_domain: {
      domain: domain,
    },
  };
  try {
    const { data } = await http.post(endpoints.domainBlacklist(), payload);
    return data.blacklist_domain;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const getDomain = async (id) => {
  const { http } = serviceHelpers;
  try {
    const { data } = await http.get(endpoints.domain(id));
    return data.blacklist_domain;
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

const removeDomain = async (id) => {
  const { http } = serviceHelpers;
  try {
    return await http.delete(endpoints.domain(id));
  } catch (error) {
    const { message } = error;
    return Promise.reject(message);
  }
};

export default {
  init: serviceHelpers.init,
  getDomainBlacklist,
  getDomainBlacklistCount,
  addDomain,
  getDomain,
  removeDomain,
};
