// accounts/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  account: (accountId) => `${helper.BASE_URL}/admin/accounts/${accountId}.json`,
  accounts: () => `${helper.BASE_URL}/admin/accounts.json`,
  accountsCount: () => `${helper.BASE_URL}/admin/accounts/count.json`,
  accountStatus: (accountId) =>
    `${helper.BASE_URL}/accounts/${accountId}/status.json`,
  accountActive: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/inactive.json`,
  accountTransactions: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/transactions.json`,
  accountStateOptions: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/account_states/options.json`,
  accountStopFiltering: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/metafields/optout/disable.json`,
  accountPlanThresholds: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/plan_thresholds.json`,
  accountPhoneVerified: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/phone_verified.json`,
  accountReseller: (accountId) =>
    `${helper.BASE_URL}/admin/accounts/${accountId}/reseller.json`,
};
