// domainblacklist/endpoints.js
import { factory } from "vivialconnect-js-core";

//NOTE: do not use spread syntax here.
//helper == {init, BASE_URL}
const helper = factory.initEndpoints();

export default {
  init: helper.init,
  domainBlacklist: () => `${helper.BASE_URL}/admin/blacklist.json`,
  domainBlacklistCount: (id) => `${helper.BASE_URL}/admin/blacklist/count.json`,
  domain: (id) => `${helper.BASE_URL}/admin/blacklist/${id}.json`,
};
